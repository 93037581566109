<template>
  <v-container @keypress.enter="save">
    <v-skeleton-loader type="image" v-if="runningRequest" />
    <v-form v-model="formValid" ref="form" v-else>
      <v-container class="px-5">
        <div class="display-1 text-center">Profil</div>
        <v-row class="mt-4">
          <v-col cols="6">
            <v-text-field
              v-model="form.email"
              type="email"
              label="Email*"
              :rules="[validation.required, validation.email, validation.maxLength(255)]"
              outlined
              dense
              class="rounded-0"
              required>
            </v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="form.nickname"
              :rules="[validation.required, validation.maxLength(255)]"
              type="text"
              label="Přezdívka*"
              outlined
              dense
              class="rounded-0"
              required>
            </v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
              ref="passwordInput"
              v-model="form.password"
              :rules="passwordRules"
              type="password"
              label="Heslo"
              outlined
              dense
              class="rounded-0"
              required>
            </v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
              ref="passwordConfirmationInput"
              v-model="form.passwordConfirmation"
              type="password"
              label="Heslo znovu"
              :rules="!!form.password ? [validation.required] : []"
              outlined
              dense
              class="rounded-0"
              required>
            </v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="form.firstName"
              type="text"
              label="Jméno"
              :rules="isHost ? [validation.required, validation.maxLength(255)] : [validation.maxLength(255)]"
              outlined
              dense
              class="rounded-0"
            >
            </v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="form.lastName"
              type="text"
              label="Příjmení"
              :rules="isHost ? [validation.required, validation.maxLength(255)] : [validation.maxLength(255)]"
              outlined
              dense
              class="rounded-0"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row class="mt-4">
          <v-col
            cols="12"
            v-if="isHost"
          >
            <v-text-field
              v-model.trim="form.companyName"
              type="text"
              label="Název firmy"
              :rules="isHost ? [validation.required, validation.maxLength(255)] : [validation.maxLength(255)]"
              outlined
              dense
              class="rounded-0"
              hide-details>
            </v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model.trim="form.ico"
              type="number"
              label="IČO"
              :rules="isHost ? [validation.required, validation.maxLength(255)] : [validation.maxLength(255)]"
              outlined
              v-if="isHost"
              dense
              class="rounded-0"
              hide-details>
            </v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model.trim="form.dic"
              v-if="isHost"
              :rules="[validation.maxLength(255)]"
              type="text"
              label="DIČ"
              outlined
              dense
              class="rounded-0"
              hide-details>
            </v-text-field>
          </v-col>
          <v-col cols="8">
            <v-text-field
              v-model.trim="form.street"
              type="text"
              label="Adresa"
              :rules="isHost ? [validation.required, validation.maxLength(255)] : [validation.maxLength(255)]"
              outlined
              dense
              class="rounded-0"
              hide-details>
            </v-text-field>
          </v-col>
          <v-col cols="4">
            <v-text-field
              v-model.trim="form.streetNumber"
              type="text"
              :rules="isHost ? [validation.required, validation.maxLength(255)] : [validation.maxLength(255)]"
              label="Číslo popisné"
              outlined
              dense
              class="rounded-0"
              hide-details>
            </v-text-field>
          </v-col>
          <v-col cols="8">
            <v-text-field
              v-model.trim="form.city"
              type="text"
              label="Město"
              :rules="isHost ? [validation.required, validation.maxLength(255)] : [validation.maxLength(255)]"
              outlined
              dense
              class="rounded-0"
              hide-details>
            </v-text-field>
          </v-col>
          <v-col cols="4">
            <v-text-field
              v-model.trim="form.zip"
              :rules="isHost ? [validation.required, validation.maxLength(255)] : [validation.maxLength(255)]"
              type="text"
              label="PSČ"
              outlined
              dense
              class="rounded-0"
              hide-details>
            </v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model.trim="form.phone"
              :rules="isHost ? [validation.required, validation.maxLength(255)] : [validation.maxLength(255)]"
              type="tel"
              label="Telefon"
              outlined
              dense
              class="rounded-0"
              hide-details>
            </v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model.trim="form.phone2"
              type="tel"
              :rules="[validation.maxLength(255)]"
              label="Druhý telefon"
              outlined
              dense
              class="rounded-0"
              hide-details>
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-btn
            color="primary"
            :disabled="!formValid || form.passwordConfirmation !== form.password"
            class="rounded-0"
            @click="save"
          >
            Uložit
          </v-btn>
        </v-row>
      </v-container>
      <v-container>
        <v-row>
          <v-col cols="12"><h3>Dobití kreditu</h3></v-col>
          <v-col cols="6">
            <v-text-field
              type="number"
              min="0"
              outlined
              class="rounded-0"
              label="Částka"
              >
            </v-text-field>
          </v-col>
          <v-col cols="6">
            <v-btn
              class="rounded-0"
              color="primary"
            >
              Dobít kredit
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
  name: 'Profile',
  data () {
    return {
      form: {
        email: null,
        nickname: null,
        password: '',
        passwordConfirmation: '',
        firstName: null,
        lastName: null,
        companyName: null,
        ico: null,
        dic: null,
        street: null,
        streetNumber: null,
        city: null,
        zip: null,
        phone: null,
        phone2: null
      },
      runningRequest: false,
      formValid: true,
      passwordRules: []
    }
  },
  computed: {
    ...mapGetters({
      user: 'userAuth/user',
      isHost: 'userAuth/isHost'
    })
  },
  methods: {
    save () {
      delete this.form.id
      delete this.form.credit
      delete this.form.propertiesCount

      if (!this.formValid || this.form.passwordConfirmation !== this.form.password) {
        return
      }
      this.runningRequest = true
      this.$http.put(`/${this.isHost ? 'hosts' : 'users'}/${this.user.id}`,
        {
          ...this.form,
          password: this.form.password || null,
          passwordConfirmation: this.form.passwordConfirmation || null,
          companyName: this.isHost ? this.form.companyName : undefined,
          ico: this.isHost ? this.form.ico : undefined,
          dic: this.isHost ? this.form.dic : undefined
        })
        .then(res => {
          this.setAlerts([{ message: 'Editace proběhla úspěšně', type: 'success' }])
          this.form = { ...this.form, ...res.data }
          this.setUser(
            {
              ...this.user,
              firstName: res.data.firstName,
              lastName: res.data.lastName,
              email: res.data.email,
              nickname: res.data.nickname
            })
        })
        .catch(err => {
          if (err.response.data.message === 'EMAIL_ALREADY_USED') {
            this.setAlerts([{
              message: 'E-mail je již používán',
              type: 'error'
            }])
          }
        })
        .finally(() => {
          this.runningRequest = false
        })

      this.$refs.passwordInput.reset()
      this.$refs.passwordConfirmationInput.reset()
    },
    ...mapMutations({
      setUser: 'userAuth/setUser'
    }),
    ...mapActions({
      setAlerts: 'alerts/setAlerts'
    })
  },
  created () {
    this.runningRequest = true
    this.$http.get(`/${this.isHost ? 'hosts' : 'users'}/${this.user.id}`)
      .then(res => {
        this.form = { ...this.form, ...res.data }
        this.runningRequest = false
      })
  },
  watch: {
    form: {
      deep: true,
      immediate: true,
      handler (val) {
        const baseRule = [this.validation.minLength(6)]
        if (val.passwordConfirmation) {
          this.passwordRules = [...baseRule, this.validation.equal(this.form.passwordConfirmation), this.validation.required]
        }
        this.passwordRules = baseRule
      }
    }
  }
}
</script>

<style scoped>

</style>
